import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import styles from '~/tailwind.css?url'
import {
  LinksFunction,
  json,
  LoaderFunctionArgs,
  MetaFunction,
} from '@vercel/remix'
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useOutletContext,
  useRevalidator,
  useRouteError,
} from '@remix-run/react'
import { useEffect, useState } from 'react'
import type { Database } from 'utils/supabase'
import { createBrowserClient } from '@supabase/ssr'
import { SpeedInsights } from '@vercel/speed-insights/remix'
import posthog from 'posthog-js'

import { Toaster } from '~/components/ui/toaster'
import { SupabaseClient } from '@supabase/supabase-js'
import { createServerClient } from '~/models/supabase.server'
import { isPostgrestError } from '@/utils/variables'
import FitError from '~/components/fit-error'

export const meta: MetaFunction = () => [
  {
    title: 'FIT - Collaborative Intelligence Tool',
  },
]

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }]

type ContextType = { supabase: SupabaseClient<any, 'public', any> }

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // We can retrieve the session on the server and hand it to the client.
  // This is used to make sure the session is available immediately upon rendering
  const { supabase, headers } = createServerClient({ request })
  const {
    data: { session },
  } = await supabase.auth.getSession()
  // in order for the set-cookie header to be set,
  // headers must be returned as part of the loader response
  return json(
    {
      env: {
        SUPABASE_URL: process.env.SUPABASE_URL!,
        SUPABASE_KEY: process.env.SUPABASE_KEY!,
        SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT!,
      },
      session,
    },
    {
      headers,
    }
  )
}

declare global {
  interface Window {
    Beamer: any
    beamer_config: {
      product_id: string
    }
    intercomSettings: {
      app_id: string
      email: string
      user_id: string
      api_base: string
    }
    Intercom: any
    env: {
      SENTRY_ENVIRONMENT: string
    }
  }
}

export function ErrorBoundary() {
  const error = useRouteError()
  let errorMessage =
    'This page either does not exist or you do not have permission to view it'
  if (isRouteErrorResponse(error) && error.data.message) {
    errorMessage = error.data.message as string
  }
  if (isPostgrestError(error) && error.message) {
    errorMessage = error.message
  }

  captureRemixErrorBoundaryError(error)

  return (
    <html>
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <FitError errorMessage={errorMessage} userData={null} />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const { env, session } = useLoaderData<typeof loader>()
  const { revalidate } = useRevalidator()
  const [supabase] = useState<SupabaseClient>(() => {
    return createBrowserClient<Database>(env.SUPABASE_URL, env.SUPABASE_KEY)
  })
  const serverAccessToken = session?.access_token
  const location = useLocation()

  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('preline')
  }, [])

  useEffect(() => {
    const exploScript = document.createElement('script')
    if (session) {
      // append explo script to head if session exists. If user logs out, remove script
      exploScript.src = 'https://embed.explo.co/bundle.js'
      document.head.append(exploScript)
    } else {
      exploScript.remove()
    }
  }, [session])

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (
        event !== 'INITIAL_SESSION' &&
        session?.access_token !== serverAccessToken
      ) {
        // server and client are out of sync.
        revalidate()
      }
      if (event === 'SIGNED_OUT') {
        // delete cookies on sign out
        const expires = new Date(0).toUTCString()
        document.cookie = `my-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`
        document.cookie = `my-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`
      } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        const maxAge = 100 * 365 * 24 * 60 * 60 // 100 years, never expires
        document.cookie = `my-access-token=${session?.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
        document.cookie = `my-refresh-token=${session?.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [serverAccessToken, supabase])

  return (
    <html lang='en'>
      <head>
        <meta httpEquiv='Content-Type' content='text/html;charset=utf-8' />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet context={{ supabase }} />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(env)}`,
          }}
        />
        <Scripts />
        <Toaster />
        <SpeedInsights />
      </body>
    </html>
  )
}

export default withSentry(App)

export function useRootContext() {
  return useOutletContext<ContextType>()
}
