import { generateIntercomScripts } from '@/utils/intercom'
import { isPostgrestError } from '@/utils/variables'
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from '@remix-run/react'
import { User } from '@supabase/supabase-js'
import { useEffect } from 'react'
import { Button } from '~/components/ui/button'
import transitivWhite from '~/images/TransitivLogoWhite.svg'
import { useRootContext } from '~/root'

export function useStandardErrorLogic() {
  const error: any = useRouteError()
  //TODO: Log error to Sentry
  let errorMessage =
    'This page either does not exist or you do not have permission to view it'
  let userData: User | null = null
  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message as string
    userData = (error.data.userData as User) ?? null
  }
  if (isPostgrestError(error)) {
    errorMessage = error.message
  }

  return <FitError errorMessage={errorMessage} userData={userData} />
}

export default function FitError({
  errorMessage,
  userData,
}: {
  errorMessage: string
  userData: User | null
}) {
  const setUpError = errorMessage === 'Account has no customers or locations.'
  const rootContext = useRootContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (userData) {
      generateIntercomScripts(userData)
    }
  }, [userData])

  const handleSignOut = async () => {
    const { error } = await rootContext?.supabase.auth.signOut()
    if (!error) {
      navigate('/login')
    }
  }

  const showNewMessage = () => {
    window.Intercom('showNewMessage')
  }

  return (
    <div className='flex h-full bg-gradient-to-tr from-transitiv-blue to-transitiv-blue-light py-16 text-center'>
      <div className='mx-auto w-full max-w-4xl'>
        <img className='mx-auto my-16' src={transitivWhite} />
        <div className='mt-7 rounded-xl border border-gray-200 bg-white shadow-sm'>
          <div className='p-4 sm:p-7'>
            <div className='mt-5'>
              <div className='mb-5 text-center text-5xl font-semibold'>
                Oops!
              </div>
              {setUpError ? (
                <div className='flex flex-col'>
                  <div className='mb-3'>
                    Your administrator has not configured your login. Please
                    contact your administrator.
                  </div>
                  <div className='mb-5'>
                    If you believe this is an error, please contact support for
                    assistance.
                  </div>
                  <Button
                    onClick={() => showNewMessage()}
                    className='mx-auto mb-5'
                  >
                    Contact Support
                  </Button>
                  <Button
                    variant='destructive'
                    onClick={handleSignOut}
                    className='mx-auto'
                  >
                    Sign Out
                  </Button>
                </div>
              ) : (
                <>
                  <p className='mb-3'>{errorMessage}</p>
                  <button
                    type='button'
                    className='disabled:bg[rgba(255,255,255,0.5)] inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-transitiv-blue px-4 py-3 text-sm font-semibold text-white transition-all hover:bg-transitiv-blue-dark focus:outline-none focus:ring-2 focus:ring-transitiv-blue focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
                    onClick={() => window.location.replace('/')}
                  >
                    Take Me Home!
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
